<template>
    <div>

        <transition>
            <Alert v-show="alertShow" :data="dataAlert" />
        </transition>

        <div class="business-brand">
            <img v-if="store.picture_url"
                 :src="store.picture_url"
                 :alt="store.name"
                 style="width: 120px"
            />
        </div>

        <div class="card-form">
<h2>Pago seguro en línea</h2>
            <form name="formPay" @submit.prevent="PayFac()">
            <!-- <form name="formPay" @submit.prevent="UpdateOrder()"> -->
                <div class="card-form__inner">
                    <div class="card-input">
                        <label for="cardNumber" class="card-input__label label">Número de tarjeta</label>
                        <input
                            type="tel"
                            :id="fields.cardNumber"
                            @input="changeNumber"
                            @focus="focusText($event)"
                            class="card-input__input"
                            :value="formData.cardNumber"
                            :maxlength="cardNumberMaxLength"
                            data-card-field
                            v-number-only
                            autocomplete="off"
                            required
                            placeholder="1234 1234 1234 1234"
                        />
                        <img
                            class="maskNumberCard"
                            :src="asset(cardTypeLogo)"
                            v-if="cardTypeLogo"
                            :key="cardTypeLogo"
                            alt="tipo de tarjeta"
                        />
                    </div>
                    <div class="card-form__group">
                        <div class="card-input">
                            <label for="cardMonth" class="card-input__label label">Fecha de Expiración</label>
                            <input
                                type="tel"
                                class="card-input__input"
                                id="cardMonth"
                                maxlength="5"
                                data-card-field
                                v-number-only
                                v-mask="'##/##'"
                                autocomplete="off"
                                v-model="cardExpDate"
                                @focus="focusText($event)"
                                @focusout="getDateExp"
                                @blur="blurTextOpacity($event)"
                                required
                                placeholder="MM/AA"
                            />
                            <div class="input-errors">
                                <div class="error-msg" v-if="cardExpDateErros">Revisar el mes y año</div>
                            </div>
                            <!--
                            Mes: {{ formData.cardMonth }}
                            Año: {{ formData.cardYear }}
                            -->

                            <!--
                          <eva-icon
                            class="eva_icon"
                            :name="validExpDateIcon.name"
                            animation="pulse"
                            :fill="validExpDateIcon.fill"
                          ></eva-icon>
                          -->
                        </div>
                        <div class="card-input">
                            <label for="cardCvv" class="card-input__label label">CVV/CVC</label>
                            <input
                                type="tel"
                                class="card-input__input"
                                v-number-only
                                :id="fields.cardCvv"
                                maxlength="4"
                                :value="formData.cardCvv"
                                @input="changeCvv"
                                data-card-field
                                autocomplete="off"
                                @focus="focusText($event)"
                                @blur="blurTextOpacity($event)"
                                required
                                placeholder="CVV"
                            />

                        </div>
                    </div>
                    <div class="card-input">
                        <label for="cardName" class="card-input__label label">Nombre en la tarjeta</label>
                        <input
                            type="text"
                            :id="fields.cardName"
                            @input="changeName"
                            class="card-input__input"
                            :value="formData.cardName"
                            v-letter-only
                            data-card-field
                            id="cardName"
                            autocomplete="off"
                            @focus="focusText($event)"
                            @blur="blurTextOpacity($event)"
                            required
                            placeholder="Nombre que aparece en tu tarjeta"
                        />
                    </div>
                    <!--
                    <div class="card-form__contentBussineBrand">
                        <img
                            src="../assets/images/LogoPowerChicken.png"
                            alt=""
                        />
                    </div>
                    -->
                    <div class="card-form__row">
                        <button class="card-form__button btn" type="submit">
                            <template v-if="!isPaying">

                                <!--
                                <inline-svg
                                    src="/images/icons/secure.svg"
                                    aria-label="Pago Seguro en Línea"
                                ></inline-svg>
                                -->

                                <inline-svg
                                    :src="asset('images/icons/secure.svg')"
                                    aria-label="Pago Seguro en Línea"
                                ></inline-svg>

                                Pagar <span style="padding-left: 4rem;"> {{ store.currency }}  {{ getAmount }}</span>
                            </template>
                            <span v-else class="lds-ellipsis"><span></span><span></span><span></span><span></span></span>
                            <!-- <div  class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div> -->
                        </button>
                    </div>
                    <div class="logos">
                        <!-- <img src="/images/visa-mastercard.svg" alt="Pago con tarjetas Visa y Mastercard" style="max-width: 70px;"> -->
                        <img :src="asset('images/logosSecurity.svg')" alt="BAC FAC PowerTranz">
                        <img :src="asset('images/vendu-pay.svg')" alt="Vendu Pay">
                    </div>
                    <div class="card-form__row text-center content-security">
                        <p>
                            Usted esta realizando una compra con pago seguro en línea de Vendu Pay,
                            <strong> no almacenamos ningún dato de su tarjeta </strong> de crédito o débito, la compra se reflejará
                            en su estado de cuenta con el nombre del comercio donde esta realizando la compra.
                        </p>
                    </div>
                </div>
                <!--
                <pre style="">{{ formData }}</pre>
                -->
            </form>

        </div>

        <vue-final-modal
            v-model="showModal"
            name="threeDSecure"
            classes="flex justify-center items-center"
            content-class="relative flex flex-col max-h-full mx-4 p-4 border rounded bg-white"
            :lock-scroll="true"
            :hide-overlay="false"
            :click-to-close="true"
            :esc-to-close="false"
            :prevent-click="true"
            transition="vfm"
            overlay-transition="vfm"
            :min-width="400"
            :min-height="600"
        >
            <div class="text-center" id="iframeContainer">
                <transition>
                    <Alert v-show="modalAlertShow" :data="modalDataAlert" />
                </transition>
            </div>
        </vue-final-modal>

    </div>
    <!--<div class="contentSecurityLogos__isMovil">
          <img src="../assets/images/logosSecurity.png" alt="Logos de seguridad">
      </div>-->
</template>

<script>
import Alert from "@/components/Alert";
import { useToast } from "vue-toastification";
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import ToastErrors from "@/components/ToastErrors";
//import axios from "axios";

//import Swal from 'sweetalert2/src/sweetalert2.js';

//import 'sweetalert2/src/sweetalert2.scss';
//import { EvaIcon } from "vue-eva-icons";
import axios from "axios";
import { inject } from 'vue'

export default {
    name: "CardFormFac",
    directives: {
        "number-only": {
            bind(el) {
                function checkValue(event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, "");
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true;
                    }
                    event.preventDefault();
                }
                el.addEventListener("keypress", checkValue);
            },
        },
        "letter-only": {
            bind(el) {
                function checkValue(event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault();
                    }
                    return true;
                }
                el.addEventListener("keypress", checkValue);
            },
        },
    },
    setup() {
        // Get toast interface
        const toast = useToast();
        const $vfm = inject('$vfm')

        // Make it available inside methods
        return { toast }
    },
    props: {
        order: Object,
        store: Object,
        hour: String,
        backgroundImage: [String, Object],
        randomBackgrounds: {
            type: Boolean,
            default: true,
        },
        shippingData: {
            type: Object,
            required: true
        },
    },
    components: {
        Alert,
        VueFinalModal,
        ModalsContainer,
        ToastErrors
    },
    data() {
        return {
            fields: {
                cardNumber: "v-card-number",
                cardName: "v-card-name",
                cardMonth: "v-card-month",
                cardYear: "v-card-year",
                cardCvv: "v-card-cvv",
            },
            translations: {},
            currentLocale: 'en', // idioma por defecto
            formData: {
                cardName: '',
                cardNumber: '',
                cardNumberNotMask: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                cardCvvNotMask: ''
            },
            minCardYear: new Date().getFullYear(),
            isCardNumberMasked: true,
            mainCardNumber: this.cardNumber,
            mainCardCvv: this.cardCvv,
            cardNumberMaxLength: 19,
            cardExpDate: "",
            cardExpDateErros: false,
            amount: this.order.total_products,
            //total: 0,
            storeId: this.order.ecwid_store_id,
            //orderID: this.order.ecwid_order_id,
            orderID: this.order.ecwid_order_number,
            currency: `transaction_${this.order.ecwid_order_id}`,
            tokenf: this.order.ecwid_custom_app_token,
            returnUrl: this.order.ecwid_return_url,
            orderNumber: "",
            addressOrder: "",
            validExpDateIcon: {},
            alertShow: false,
            dataAlert: {
                title: "Título",
                animationPath: "assets/lotties/wait.json",
                description: "Descripción de la alerta",
            },
            modalAlertShow: false,
            modalDataAlert: {
                title: "Procesando pago",
                animationPath: "assets/lotties/loader_vendu.json",
                description: "Por favor no cierre esta ventana",
            },
            deliveryAddress: null,
            deliveryEmail: null,
            deliveryStreet: null,
            deliveryPhone: null,
            deliveryName: null,
            isPaying: false,
            deliveryCity: null,
            deliveryPostalCode: null,
            deliveryCountry: null,
            productsItems: [],
            ipAddress: null,
            sessionIDPromerica: null,
            typeLogoCard: null,
            showModal: false
        };
    },
    computed: {
        cardTypeLogo() {
            let number = this.formData.cardNumber;
            let re = new RegExp("^4");
            if (number.match(re) != null) return "img/maskVisa.png";

            re = new RegExp("^(34|37)");
            if (number.match(re) != null) return "img/maskAmex.png";

            re = new RegExp("^5[1-5]");
            if (number.match(re) != null) return "img/maskMastercard.png";

            return ""; // default type
        },
        minCardMonth() {
            if (this.formData.cardYear === this.minCardYear)
                return new Date().getMonth() + 1;
            return 1;
        },
        paymentProvider() {
            return this.$page.props.payOnlineWithCard;
        },
        getAmount() {
            return Number(this.amount).toFixed(2);
        },
    },
    watch: {
        cardYear() {
            if (this.formData.cardMonth < this.minCardMonth) {
                this.formData.cardMonth = "";
            }
        },
        cardExpDate(newCardExpDate, oldCardExpDate)
        {
            if (newCardExpDate !== '') {
                this.getDateExp();
            }
        }
    },
    created() {
        this.getProductsItems(this.orderID);

        // if(this.shippingData.typeShipping === "Delivery"){

            let that = this;

            axios.get(`https://app.ecwid.com/api/v3/${this.storeId}/orders/${this.orderID}?token=${this.tokenf}`)
                .then(resp => {
                    that.deliveryEmail = resp.data.email;
                })
                .catch(err => console.log(err));

        //}

        //this.deliveryPhone = this.shippingData.params.billingPerson.phone;
        //this.deliveryName = this.shippingData.params.billingPerson.name;
    },
    mounted() {
        //this.maskCardNumber();
        //this.maskCardCvv();
        //console.log('shippingData: ', this.shippingData);
        //console.log("returnUrl: ", this.returnUrl)

        this.amount = this.shippingData.amountFinal || this.order.total_products;
        this.sessionIDPromerica = this.$page.props.sessionID;

        /*
        * Test card
        */
        if(this.paymentProvider.paymentable.enabled_test_mode)
        {
            this.setTestCard()
        }

        window.addEventListener('message', (event) => {
            if (event.data.status === '3DS-authentication-complete') {
                this.on3DSComplete(event.data.order_identifier);
            }
        }, false);


        // this.loadTranslations();

    },
    methods: {
        on3DSComplete(order_identifier) {
            // Hide the 3DS UI
            let iframeContainer = document.getElementById('iframeContainer');

            iframeContainer.querySelector('iframe').remove();

            this.dataAlert.title = 'Procesando Pago'
            this.dataAlert.animationPath = 'assets/lotties/loader_vendu.json'; // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
            this.dataAlert.description = 'Por favor no cierre esta ventana';
            this.alertShow = true; // muestra/oculta la alerta (true/false)

            this.$vfm.hide('threeDSecure')

                axios.post(`/api/payment/intent/${order_identifier}`) // ${this.orderID}
                    .then( result => {

                        console.log("paymentIntent: ", result.data);

                        let paymentIntent = result.data;

                        if (paymentIntent.status === 'succeeded') {
                            // Show your customer that the payment has succeeded

                            this.dataAlert.title = 'Pago exitoso'
                            this.dataAlert.animationPath = 'assets/lotties/sucess.json'
                            //this.dataAlert.urlDescriptionImg = 'assets/lotties/transaccion-exitosa.svg'
                            //this.alertShow = true;

                            this.UpdateOrder()

                           console.log("Pago exitoso")

                        }
                        else if (paymentIntent.status === 'requires_payment_method') {
                            this.alertShow = false;

                            if (paymentIntent.transaction_three_d_secure_errors) {
                                this.toast.error({
                                        component: ToastErrors,
                                        props: {
                                            errors: paymentIntent.transaction_three_d_secure_errors
                                        },
                                    },
                                    {
                                        hideProgressBar: true,
                                        timeout: false
                                    })

                                return 0;
                            }
                        } else if (paymentIntent.status === 'requires_action_authorize' ||
                            paymentIntent.status === 'requires_action_capture'
                        ) {
                            this.alertShow = false;

                            if (paymentIntent.errors) {

                                this.toast.error({
                                        component: ToastErrors,
                                        props: {
                                            errors: paymentIntent.errors
                                        },
                                    },
                                    {
                                        hideProgressBar: true,
                                        timeout: false
                                    })

                                return 0;
                            }
                        }

                        /*
                        this.toast.info(`No se realizo ningún cobro:`, {
                            hideProgressBar: true,
                        })
                        */

                    })
                    .catch( error => {
                        console.log(error);
                    })
                    .finally(

                    )

            // Check the PaymentIntent
            /*
            stripe.retrievePaymentIntent('{{PAYMENT_INTENT_CLIENT_SECRET}}')
                .then(function(result) {
                    if (result.error) {
                        // PaymentIntent client secret was invalid
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            // Show your customer that the payment has succeeded

                            this.UpdateOrder()


                        } else if (result.paymentIntent.status === 'requires_payment_method') {
                            // Authentication failed, prompt the customer to enter another payment method
                        }
                    }
                });
           */
        },
        toCamel (o) {
            var newO, origKey, newKey, value
            if (o instanceof Array) {
                return o.map(function(value) {
                    if (typeof value === "object") {
                        value = toCamel(value);
                    }
                    return value
                });
            } else {
                newO = {}
                for (origKey in o) {
                    if (o.hasOwnProperty(origKey)) {
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                        value = o[origKey]
                        if (value instanceof Array || (value !== null && value.constructor === Object)) {
                            value = toCamel(value)
                        }
                        newO[newKey] = value
                    }
                }
            }
            return newO
        },
        async getProductsItems() {
            try {
                const res = (await axios.get(`https://app.ecwid.com/api/v3/${this.storeId}/orders/${this.orderID}?token=${this.tokenf}`)).data;
                this.productsItems = res.items.map(product => ({
                    productCode: String(product.productId),
                    productName: product.name,
                    productSku: product.sku,
                    quantity: product.quantity,
                    unitPrice: String(product.price),
                }));

                //console.log("productsItems: ", this.productsItems);

            } catch (error) {
                console.log(error);
            }
        },
        async PayFac() {

            let finalNumberCard = this.formData.cardNumber.split(' ').join('');
            //
            let data = {
                paymentProvider: this.paymentProvider,
                store: {
                    uuid: this.store.uuid
                },
                order: {
                    uuid: this.order.uuid
                },
                clientReferenceInformation: {
                    code: this.orderID //"TC50171_3"
                },
                shippingInformation: {
                    // shippingCarrierName: this.shippingData.params.shippingOption.shippingCarrierName || '', // input optional in Pickup
                    shippingMethodId: this.shippingData.shippingMethod ? this.shippingData.shippingMethod.id : 0,
                    shippingMethodName: this.shippingData.params.shippingOption.shippingMethodName,
                    // fulfillmentType:  this.shippingData.params.shippingOption.fulfillmentType,
                    shippingRate: this.shippingData.params.shippingOption.shippingRate || 0, // input optional in Pickup
                    isPickup: this.shippingData.params.shippingOption.isPickup
                },
                paymentInformation: {
                    card: {
                        number: finalNumberCard, //finalNumberCard,
                        expirationMonth: this.formData.cardMonth,
                        expirationYear: this.formData.cardYear,
                        type: this.GetCardType(finalNumberCard),
                        //type: this.getTypeCreditCard(finalNumberCard), // 001 - visa, 002 - , 003 -
                        securityCode: this.formData.cardCvv,
                        name: this.formData.cardName,
                    }
                },
                orderInformation: {
                    amountDetails: {
                        totalAmount: this.getAmount,
                        currency: this.store.currency, // "HNL" //USD //HNL
                    },
                    billTo: {
                        firstName: this.shippingData.params.billingPerson.name, //this.deliveryName,
                        lastName: this.shippingData.params.billingPerson.name, //this.deliveryName,
                        //address1: this.deliveryAddress + this.deliveryStreet,
                        address1: this.shippingData.params.billingPerson.street, //`${this.shippingData.addressSelected.name}, ${this.shippingData.addressDetails}` ,
                        locality: this.shippingData.params.billingPerson.city, // this.deliveryCity || 'Tegucigalpa',
                        //administrativeArea: "FM", // Todo: call this parameter from the database,
                        postalCode: this.shippingData.params.billingPerson.postalCode, //"11101" || this.deliveryPostalCode,
                        country: this.shippingData.params.billingPerson.countryCode, //this.deliveryCountry || 'Honduras',
                        email: this.deliveryEmail,
                        phoneNumber: this.shippingData.params.billingPerson.phone //this.deliveryPhone
                    },
                    lineItems: this.productsItems,
                },
                /*
              merchantDefinedInformation: [
                {
                  key: "1",
                  value: "Web"
                },
                {
                  key: "3",
                  value: "Gran Linfa"
                },
                {
                  key: "5",
                  value: finalNumberCard.slice(0,6)
                },
                {
                  key: "7",
                  value: "Invitado"
                },
                {
                  key: "8",
                  value: "10"
                },
                {
                  key: "9",
                  value: "S"
                },
                {
                  key: "10",
                  value: this.deliveryPhone
                },
                {
                  key: "14",
                  value: "No"
                }
              ]
              */
            };
            //console.log(JSON.stringify(data));

            //await this.UpdateOrder();

            //return

            if(
                this.formData.cardNumber !== '' &&
                this.formData.cardName !== '' &&
                this.formData.cardMonth !== '' &&
                this.formData.cardYear  !== '' &&
                this.formData.cardCvv !== ''
            )
            {

                // lottie
                this.dataAlert.title = 'Procesando Pago'
                this.dataAlert.animationPath = 'assets/lotties/loader_vendu.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                this.dataAlert.description = 'Por favor no cierre esta ventana';
                this.alertShow = true; // muestra/oculta la alerta (true/false)

                // console.log("DataFAC: ", data);
                // return
                try {

                    const response = (
                        await axios.post(
                            "/payment/pay/fac/riskMgmt",
                            data
                        )
                    ).data;
                    //).data;

                    if(response.iframe){
                        this.alertShow = false; // muestra/oculta la alerta (true/false)
                        this.modalAlertShow = false;

                        let iframe = document.createElement("iframe");
                        let iframeContainer = document.getElementById("iframeContainer");

                        iframe.setAttribute("srcdoc", response.iframe);
                        iframe.width = 400; //600; // 1200
                        iframe.height = 600; //400; //900
                        //iframe.style.width = "600px";
                        //iframe.style.height = "600px";

                        iframeContainer.appendChild(iframe);

                        this.$vfm.show('threeDSecure')

                        //this.showModal = true;


                    } else {

                        this.alertShow = false; // muestra/oculta la alerta (true/false)

                    }
                    //console.log(response);
                } catch (error) {

                    console.log("Api call erros: ", error);

                    this.alertShow = false;

                    this.toast.error(`El sistema encontró errores: ${error.body} ${error.message} `, {
                        hideProgressBar: true,
                    })

                    /*
                    this.dataAlert.title = 'El sistema encontró errores'
                    this.dataAlert.animationPath = 'assets/lotties/error-animate.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                    this.dataAlert.description = response.body + '<br> ' + response.message + '<br> ' +
                        "Transacción ID: " +  response.transactionId + '<br> ' +
                        "Estatus: " +  response.status + '<br> ' +
                        "Estatus Code: " +  response.statusCode + '<br> ' +
                        "Error Razón: " +  response.error.reason + '<br> ' +
                        "Error Mensaje: " +  response.error.message + '<br> ' +
                        "Error Detalles: " +  response.error.details
                    ;
                    this.alertShow = true; // muestra/oculta la alerta (true/false)
                    */
                }
            } else {
                this.toast.info("Revise los datos ingresados de su tarjeta", {
                    hideProgressBar: true,
                })
            }
        },
        getDateExp() {
            let arrayDate = this.cardExpDate.split("/");

            console.log("arrayDate: ", arrayDate)

            let months = [],
                years = [],
                validMonth = "",
                validYear = "";
            // months and years
            for (let i = 0; i <= 12; i++) {
                months.push(i < 10 ? "0" + i : "" + i); // agrega los 12 meses
                years.push((this.minCardYear + i).toString()); // agrega 12 años a partir del actual
            }
            validMonth = months.find((month) => month === arrayDate[0]);
            validYear = years.filter((year) => year.substring(2, 4) === arrayDate[1]);

            if (validMonth !== undefined) {
                this.formData.cardMonth = validMonth;
                //this.$emit("input-card-month", this.formData.cardMonth);
            } else
            {
                this.formData.cardMonth = '';
            }

            if (validYear.length > 0) {
                this.formData.cardYear = validYear[0];
                //this.$emit("input-card-year", this.formData.cardYear);
            } else {
                this.formData.cardYear = '';
            }

            if (
                validYear.length > 0 &&
                validMonth !== undefined &&
                validMonth !== "00" &&
                this.cardExpDate !== ""
            ) {
                this.validExpDateIcon = {
                    name: "checkmark-circle-2",
                    fill: "limegreen",
                };
                this.cardExpDateErros = false;
            } else if (this.cardExpDate === "") {
                this.validExpDateIcon = {};
            } else {
                this.validExpDateIcon = { name: "close-circle", fill: "red" };
                this.cardExpDateErros = true;
                //this.formData.cardMonth = "";
                //this.formData.cardYear = "";
            }
        },
        changeName(e) {
            // actualiza data del campo del nombre
            this.formData.cardName = e.target.value;
            this.$emit("input-card-name", this.formData.cardName);
        },
        changeNumber(e) {
            //actualiza en data el campo del número de tarjeta
            this.formData.cardNumber = e.target.value;
            let value = this.formData.cardNumber.replace(/\D/g, "");
            // american express, 15 digits
            if (/^3[47]\d{0,13}$/.test(value)) {
                this.formData.cardNumber = value
                    .replace(/(\d{4})/, "$1 ")
                    .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
                this.cardNumberMaxLength = 17;
            } else if (/^\d{0,16}$/.test(value)) {
                // regular cc number, 16 digits
                this.formData.cardNumber = value
                    .replace(/(\d{4})/, "$1 ")
                    .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
                    .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
                this.cardNumberMaxLength = 19;
            }
            // eslint-disable-next-line eqeqeq
            if (e.inputType == "deleteContentBackward") {
                let lastChar = this.formData.cardNumber.substring(
                    this.formData.cardNumber.length,
                    this.formData.cardNumber.length - 1
                );
                // eslint-disable-next-line eqeqeq
                if (lastChar == " ") {
                    this.formData.cardNumber = this.formData.cardNumber.substring(
                        0,
                        this.formData.cardNumber.length - 1
                    );
                }
            }
            this.$emit("input-card-number", this.formData.cardNumber);
        },
        changeMonth() {
            this.$emit("input-card-month", this.formData.cardMonth);
        },
        changeYear() {
            this.$emit("input-card-year", this.formData.cardYear);
        },
        changeCvv(e) {
            this.formData.cardCvv = e.target.value;
            this.$emit("input-card-cvv", this.formData.cardCvv);
        },
        invalidCard() {
            let number = this.formData.cardNumberNotMask.replace(/ /g, "");
            var sum = 0;
            for (var i = 0; i < number.length; i++) {
                var intVal = parseInt(number.substr(i, 1));
                if (i % 2 === 0) {
                    intVal *= 2;
                    if (intVal > 9) {
                        intVal = 1 + (intVal % 10);
                    }
                }
                sum += intVal;
            }
            if (sum % 10 !== 0) {
                alert("Error");
            }
        },
        blurCardNumber(event) {
            if (this.isCardNumberMasked) {
                this.maskCardNumber();
            }
            event.target.style.color = "#9EA0A0";
        },
        blurTextOpacity(event) {
            event.target.style.color = "#9EA0A0";
        },
        focusText(event) {
            event.target.style.color = "#444950";
        },
        maskCardNumber() {
            // enmascara el número de la tarjeta
            this.formData.cardNumberNotMask = this.formData.cardNumber;
            this.mainCardNumber = this.formData.cardNumber;
            let arr = this.formData.cardNumber.split("");
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== "") {
                    arr[index] = "·";
                }
            });
            this.formData.cardNumber = arr.join("");
        },
        unMaskCardNumber() {
            this.formData.cardNumber = this.mainCardNumber;
        },
        focusCardNumber() {
            //this.unMaskCardNumber()
        },
        maskCardCvv() {
            // enmascara el numero de cvv/cvc
            //this.formData.cardNumberNotMask = this.formData.cardNumber
            this.cardCvvNotMask = this.formData.cardCvv;
            this.mainCardCvv = this.formData.cardCvv;
            let arr = this.formData.cardCvv.split("");
            arr.forEach((element, index) => {
                if (index > 1 && index < 2 && element.trim() !== "") {
                    arr[index] = "·";
                }
            });
            this.formData.cardCvv = arr.join("");
            //this.formData.cardNumber = arr.join('')
        },
        toggleMask() {
            this.isCardNumberMasked = !this.isCardNumberMasked;
            if (this.isCardNumberMasked) {
                this.maskCardNumber();
            } else {
                this.unMaskCardNumber();
            }
        },
        formClean() {
            //this.amount = 0;
            this.formData.cardNumberNotMask = "";
            this.formData.cardName = "";
            this.formData.cardCvv = "";
            this.formData.cardMonth = "";
            this.formData.cardYear = "";
        },
        async UpdateOrder() {

            //let rshop = transactions
            // var myJSON = { "paymentStatus": "PAID", "referenceTransactionId": rshop};
            let params = {
                'paymentStatus': 'PAID',
                //"orderComments": this.hour
                // "referenceTransactionId": rshop
                "externalTransactionId": this.$page.props.sessionID
            }

            //if(this.shippingData.typeShipping === "Delivery"){
            params = Object.assign({'paymentStatus': 'PAID'}, this.shippingData.params);
            //}

            let url = `https://app.ecwid.com/api/v3/${this.storeId}/orders/transaction_${this.orderID}?token=${this.tokenf}`;

            //var xhr = new XMLHttpRequest()
            // console.log(params)
            /*
            xhr.addEventListener('readystatechange', function() {
                if (this.readyState === this.DONE) {
                    //console.log(this.responseText)
                }
            })
            */

            let tok = this.tokenf
            /*
            xhr.open('PUT', url)
            xhr.setRequestHeader('content-type', 'application/json')
            // console.log("URL FINAL: "+ "https://app.ecwid.com/api/v3/32268563/orders/$shopID?token=secret_mzvcQyumPLZuJU7fyagaAEpY71DRNUtc")
            xhr.send(JSON.stringify(params))
            */

            let storeReturnUrl = this.returnUrl;

            try {
               //const orderUpdatePaymentStatus = await axios.put(url, params);
                //let shippingPerson = Object.assign({'shippingPerson': {}}, params.shippingPerson);
               const orderUpdateShippingPerson = await axios.put(url, params);
                //console.log("orderUpdateShippingPerson: ", orderUpdateShippingPerson)
                //window.location.replace(storeReturnUrl);

                //if(this.shippingData.typeShipping === "Delivery" && storeIdYoVoy.includes(this.storeId)){

                console.log("Revisando redireccionamiento")

                //console.log(this.shippingData.shippingMethod.courierable !== null)

                if(this.shippingData.typeShipping === "Delivery" && typeof this.shippingData.shippingMethod !== 'undefined' ){

                    /*
                    Envío con Yo voy
                     */
                    if ( typeof this.shippingData.shippingMethod.courierable !== 'undefined' &&
                        this.shippingData.shippingMethod.courierable !== null
                    ) {
                        await this.deliveryYoVoy().then(res => {
                            //if(res) setTimeout(function(){  window.location.replace(url+"&clientId=custom-app-"+jshop+"-1"); }, 6000);

                            console.log("Redireccionamiento cuando es Delivery con Yo Voy: ", storeReturnUrl)

                            if (res) setTimeout(function() { window.location.replace(storeReturnUrl) }, 2000)
                        }).catch(err => console.log(err));
                    } else {

                        /*
                            Envío delivery propio.
                         */
                        console.log("Redireccionamiento cuando es Delivery es Propio: ", storeReturnUrl)

                        setTimeout(function() { window.location.replace(storeReturnUrl) }, 2000)

                    }

                }else if (this.shippingData.typeShipping === "Pickup"){

                    console.log("Redireccionamiento cuando es Pickup a la tienda: ", storeReturnUrl)

                    setTimeout(function() { window.location.replace(storeReturnUrl) }, 2000)
                }else{
                    console.log("Redireccionamiento a la tienda: ", storeReturnUrl)

                    setTimeout(function() {

                       window.location.replace(storeReturnUrl)

                    }, 2000)
                }
            }
            catch(errors){
                console.log("Errors cardFormFac update order: ", errors)
            }

            /*
            axios.put(url, params).then(res => {

                console.log("Url de redireccionamiento: ", storeReturnUrl)

                console.log("Respuesta de Ecwid: ", res)

                //window.location.replace(storeReturnUrl)


            }).catch(err => console.log(err));
            */


            // var finalUrl = url + '&clientId=custom-app-32268563-2'
            // console.log(finalUrl)
            /**
             var param = {
                      'returnURL': url
                    }
             **/
                // this.openPost('https://pay.powerchicken.hn/anim/exito.php', 'width=1000, height=600, left=100, top=100, resizable=yes, scrollbars=yes', 'NewFile', param)


        },
        GetCardType(number) {
            // visa
            //let re = new RegExp('^4')
            let re = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
            if (number.match(re) != null) { return { name: 'Visa', value: '001' } }

            // Todo, check that mastercard can read card that start with 5 y 2
            // Mastercard
            //re = new RegExp('^5[1-5]')
            //re = new RegExp('^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$')
            re = new RegExp('^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$')
            if (number.match(re) != null) { return { name: 'Mastercard', value: '002' } }

            // AMEX
            re = new RegExp('^3[47]')
            if (number.match(re) != null) { return { name: 'Amex', value: '003' } }

            // Discover
            re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
            if (number.match(re) != null) { return { name: 'Discover', value: '004' } }

            // Diners
            re = new RegExp('^36')
            if (number.match(re) != null) { return { name: 'Diners', value: '005' } }

            // Diners - Carte Blanche
            re = new RegExp('^30[0-5]')
            if (number.match(re) != null) { return { name: 'Diners - Carte Blanche', value: '006' } }

            // JCB
            re = new RegExp('^35(2[89]|[3-8][0-9])')
            if (number.match(re) != null) { return { name: 'JCB', value: '007' } }

            // Visa Electron
            re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
            if (number.match(re) != null) { return { name: 'Visa Electron', value: '033' } }

            return '';
        },
        setTestCard()
        {
            this.formData.cardName = 'Sabid Barahona';
            this.formData.cardNumber = '4012000000020006';
            this.formData.cardMonth = '12';
            this.formData.cardYear = '25';
            this.formData.cardCvv = '123';
            this.cardExpDate = '1225';
        },
        async deliveryYoVoy() {

            /*
            {
                shopId: Number(this.storeId),
                deliveryPhone: this.deliveryPhone,
                deliveryEmail: this.deliveryEmail,
                deliveryAddress: `${this.shippingData.addressSelected.direccion} - ${this.shippingData.addressDetails}`,
                deliveryName: this.deliveryName,
                deliveryLatitud: this.shippingData.addressSelected.latitude,
                deliveryLongitud: this.shippingData.addressSelected.longitude,
                orderComments: this.shippingData.params.orderComments
            }
             */

            let latitude = 0,
                longitude = 0,
                pickupName = null,
                params = {};
            let pickupEmail = null;

            params.apiToken = "iD4fNt4li3QfuvkL0OdzcG9S51Ao1WNO";

            let datePickup = new Date();
            datePickup.setUTCMinutes(new Date().getMinutes() + 5);

            let dateDelivery = new Date();
            dateDelivery.setUTCMinutes(new Date().getMinutes() + 20 );

            params.pickup = {
                latitude:  this.store.couriers[0]['courierable']['latitude'],
                longitude:  this.store.couriers[0]['courierable']['longitude'],
                email:  this.store.email, //pickupEmail,
                phone: this.store.phone ?? "+50425441717",  // "+50425441717",
                name: this.store.name, //pickupName,
                notes: '',
                reference: '',
                vehicle: 0, // 0 for bike, 1 for car, 2 for light truck
                date: datePickup.toUTCString()
            }

            params.delivery = {
                latitude: Number(this.shippingData.addressSelected.latitude),
                longitude: Number(this.shippingData.addressSelected.longitude),
                email: this.deliveryEmail, // TODO: this.deliveryEmail === this.order.ecwid_order_email
                phone: this.shippingData.params.billingPerson.phone, //data.deliveryPhone,
                name: this.shippingData.params.billingPerson.name, //data.deliveryName,
                notes: "", //`${data.orderComments}`,
                reference: this.shippingData.params.billingPerson.street, //`${data.deliveryAddress}`,
                cashOnDelivery: 0,
                date: dateDelivery.toUTCString()
            }

            return new Promise(async (resolve, reject) => {
                try {
                    const response = await axios.post('https://integrations.yovoyenvios.com/api/delivery/', params);
                    const orderDone = response.data;
                    if (orderDone.success) return resolve(orderDone.success);
                } catch (error) {
                    console.log(error);
                    return reject(false);
                }
            });
        },

        /*
        loadTranslations() {
            // Establecer el idioma actual basado en la propiedad store.language
            const lenguage = this.store.language || this.currentLocale;

            if (lenguage === 'en') {
                import('@/en.json').then((module) => {
                    this.translations = module.default;
                    // console.log("translationsEnglish: ", this.translations);
                });
            } else {
                import('@/es.json').then((module) => {
                    this.translations = module.default;
                    // console.log("translationsESPAÑOL: ", this.translations);
                });
            }
        }
        */
    },
};
</script>

<style scoped>

#iframeContainer {
    position: relative;
}

#iframeContainer .alert {
    width: 100%;
    min-height: 100%;
    position: absolute;
}

.spinner {
    margin:0 auto;
    width: 70px;
    text-align: center;
}

h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 10px;
}
.lds-ellipsis span {
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* Modal styles */

::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}


</style>
