<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <div class="bg-white shadow-md rounded-lg p-6 text-center">
            <img v-if="store.picture_url" :src="store.picture_url" :alt="store.name" class="w-32 h-32 mx-auto mb-4"/>
            <h1 class="text-2xl font-bold mb-2">{{ store.name }}</h1>
            <div class="text-red-800 p-4 rounded-md">
                <p class="text-lg font-semibold">Lo sentimos, actualmente estamos cerrados.</p>
                <p class="text-sm">Por favor, vuelve a visitarnos durante nuestro horario de apertura.</p>
                <p class="text-sm">Agradecemos tu comprensión y esperamos verte pronto.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        store: {
            type: Object,
            required: true,
        },
    },
};
</script>

